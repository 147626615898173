export const matters = [
  "office@dehancer.com",
  "marketing@dehancer.com",
  "instagram",
  "mail@dehancer.com",
  "kondratievavik@dehancer.com",
  "pr@dehancer.com",
  "anfisazel@dehancer.com",
  "franco@dehancer.com",
  "sofya@dehancer.com",
  "kirill@dehancer.com",
  "liya@dehancer.com",
  "dana@dehancer.com",
  "camilla@dehancer.com",
];
